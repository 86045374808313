<div style="display: flex; flex-direction: column;" class="containerX" fxLayoutAlign="center center">
  <mat-card style="max-width: 500px; margin-top:1em; padding: 0 1.5em 0.5em 1.5em;" class="sign-up-mat">
    
    @if(loading === true){
      <div class="data-loading-spinner" >
        <mat-spinner style="margin: auto" color="primary"> </mat-spinner>
      </div>
    }

    <mat-card-content class="signup-form">
      <form [formGroup]="signUpForm" #formDir="ngForm">
        <div class="signup-form-container box">
          <div class="account-details">
            <div style="text-align: center">
              <div class="signup-title">Create an account</div>
            </div>

            <div style="display: flex; flex-direction: column;">
              <div class="flex-item" fxFlex="30" fxFlex.xs="100">
                <mat-form-field class="signup-property">
                  <input
                    matInput
                    [errorStateMatcher]="matcher"
                    formControlName="username"
                    placeholder="Username"
                    required
                  />
                  <!-- If the error matcher does not switch change input get to invalid and maybe touched -->
                  @if(showForm){ 

                    <mat-error>
                      <lib-mat-form-error-component
                      [inputControl]="signUpForm.get('username')!.errors"
                      >
                    </lib-mat-form-error-component>
                  </mat-error>
                }
                </mat-form-field>

                <mat-form-field class="signup-property">
                  <input
                    matInput
                    [errorStateMatcher]="matcher"
                    type="text"
                    placeholder="Email"
                    id="email"
                    formControlName="email"
                  />
                  @if(showForm){ 

                    <mat-error>
                      <lib-mat-form-error-component
                      [inputControl]="signUpForm.get('email')!.errors"
                      >
                    </lib-mat-form-error-component>
                  </mat-error>
                }
                </mat-form-field>
              </div>

              <div class="flex-item" fxFlex.xs="100">
                <mat-form-field class="signup-property">
                  <input
                    matInput
                    [errorStateMatcher]="matcher"
                    type="text"
                    placeholder="First Name"
                    formControlName="firstName"
                    required
                  />
                  <mat-error>
                    <lib-mat-form-error-component
                      [inputControl]="signUpForm.get('firstName')!.errors"
                    >
                    </lib-mat-form-error-component>
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="signup-property">
                  <input
                    matInput
                    [errorStateMatcher]="matcher"
                    type="text"
                    placeholder="Last Name"
                    formControlName="lastName"
                  />

                  @if(showForm){ 
                    <mat-error>
                      <lib-mat-form-error-component
                      [inputControl]="signUpForm.get('lastName')!.errors"
                      >
                    </lib-mat-form-error-component>
                  </mat-error>
                }
                </mat-form-field>
              </div>

              <div class="flex-item" fxFlex>
                <mat-form-field class="signup-property">
                  <div style="display: flex">
                    <div style="width: 100%">
                      <input
                        matInput
                        [errorStateMatcher]="matcher"
                        [type]="passwordType$ | async"
                        formControlName="password"
                        placeholder="Password"
                        required
                      />
                    </div>
                    <div>

                        <lib-svg-icon
                        [color]="'darkGrey'"
                        [iconName]="password_icon$ | async"
                        [size]="'small'"
                        (click)="showPasswordClicked()"
                        ></lib-svg-icon>
                      </div>
                  </div>
                  <!-- If the error matcher does not switch change input get to invalid and maybe touched -->
                  @if(showForm){ 
                    <mat-error>
                      <lib-mat-form-error-component
                        [inputControl]="signUpForm.get('password')!.errors"
                        [props]="{minLength:7, maxLength:23}"
                        >
                      </lib-mat-form-error-component>
                    </mat-error>
                  }
                </mat-form-field>
              </div>
            </div>
          </div>

          <mat-card-actions layout="row" class="submit-actions-holder">
                <div style="width: 100%; margin-bottom: 1em; display: flex;">
                  <div style="flex:2;">

                    <button
                    mat-raised-button
                    [disabled]="!isValid$.value"
                    (click)="signUp()"
                    [ngClass]="{'disabled-button': isValid===false, 'signup-button':isValid!==false }" 
                    >
                    Submit
                  </button>
                </div>

                <div style="flex:1;">
                  <button
                    mat-raised-button
                    type="reset"
                    style="width: 100%"
                    color="alert"
                  >
                    Reset
                  </button>
                </div>
                </div>
            </mat-card-actions>
        </div>
      </form>

    </mat-card-content>
  </mat-card>
</div>
