import { CommonModule } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { Router } from '@angular/router'; 
import { AuthPasswordStore, AuthStatusStore } from '@sybl/feature-auth-state'
import { MatCardModule } from '@angular/material/card'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
    selector: 'lib-password-changed',
    standalone: true,
    imports: [CommonModule, MatProgressSpinnerModule, MatCardModule],
    templateUrl: 'password-changed.component.html',
    styleUrls: ['password-changed.scss']
})
export class PasswordChangedComponent {
    readonly authStore = inject(AuthStatusStore);
    readonly passwordStore = inject(AuthPasswordStore);
    passwordChangedUrl:string;


    get avatar(){ return this.authStore.avatar()}
    get user_id(){ return this.authStore.user_id()}
    get username(){ if(this.authStore.email()){return  this.authStore.email().split('@')[0]}else return '' }
    get usernameInitial() { if (this.username !== undefined) return this.username.substring(0, 1).toUpperCase(); else return '' }
    get permissions(){return this.authStore.permissions()}

    get email(){ return this.authStore.email()}
    get passwordChanged(){ return this.passwordStore.passwordChanged()}
    get loading(){ return this.passwordStore.passwordLoading()}
    
    
    constructor(
        @Inject('appUrls') private appUrls: any, // EnvironmentAppUrls, 
        private router: Router
    ){
        this.passwordChangedUrl = this.appUrls.IMAGES_URL + "passwordChanged.svg"

    }       
    
    navigateHome(){
        this.router.navigate(['/home'])
    }
    
    changePassword(){
        this.router.navigate(['/authorization/change-password'])
    }

}