import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { AppStatusStore } from '@sybl/core-state';
import { CommonModule } from '@angular/common';

import { SignUpComponent } from '../../components'
import { AuthStatusStore } from '@sybl/feature-auth-state';

@Component({
    selector: 'lib-sign-up-smart',
    standalone: true,
    imports: [CommonModule, SignUpComponent],
    template: `
        <div class="smart-page-holder circle-background-images"> 
            <div style="margin:auto;">
                <lib-sign-up 
                    [siteName]="siteName"
                    [loading]="loading"
                    (signUpOut)="signUpReceived($event)"
                ></lib-sign-up>
            </div>
        </div>
    `
})
export class SignUpSmartComponent implements OnInit, OnDestroy {
    signUpReferer: string;
    readonly appStore = inject(AppStatusStore);
    readonly authStore = inject(AuthStatusStore)

    get siteName(){return this.appStore.siteName()}

    loading = this.appStore.loading();
    _onDestroy = new Subject()
    constructor(
        private route: ActivatedRoute,
    ) {
        if(typeof window !=='undefined'){

          //  this.appStore.goToTop();
            
            
            this.route.params
            .pipe(
                map((params: any) => {
                    if(params.referer!== undefined){
                        this.signUpReferer = params.referer
                    }
                    
                }),
                takeUntil(this._onDestroy)
                
            )
            .subscribe()
            
        }

    }

    ngOnInit(): void {
        return
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        return this._onDestroy.next(true)
    }
    signUpReceived(signupDetails) {

        if (this.signUpReferer !== undefined) {
            //signUpReferer
            signupDetails.siteName===this.signUpReferer
        }

        return this.authStore.signUp(signupDetails);
    }


}
