
<div class="smart-page-holder circle-background-images"> 
    <div   style="display: flex; flex-direction: column; width: 100%; justify-content: center; margin-top: 76px;"
    class="change-password-container"  fxLayoutAlign="center center">

        <mat-card class="change-password-mat">

            <div style="text-align:center; margin-bottom: .5em;">
                <div class="change-password-title "> Enter a new password for</div>

                @if(avatar===undefined && permissions >= 3){
                    <div class="logout-avatar-holder ">
                      <div
                        style="
                          background-color: whitesmoke;
                          border-radius: 63px;
                          height: 123px;
                          width: 123px;
                          display: flex;
                          margin: 1em auto;
                        "
                      >
                        <div style="color: #ce6925; 
                            margin: auto;
                            font-size: 6em;
                            font-weight: 600;
                        ">
                          {{ usernameInitial }}
                        </div>
                      </div>
                    </div>
                    } @else if(avatar !== undefined && permissions >= 3){
                        <div class="logout-avatar-holder">
                            <img class="logout-avatar" [src]="avatar">
                        </div>
                    } 

                <div class="change-password-sub-title">
                    {{email}}
                </div>

                <lib-change-password-form 
                    [loading]='loading'
                    [email]= 'email'
                    [user_id]='user_id'
                    [jwToken]='jwToken'
                    >
                </lib-change-password-form>

            </div>

        </mat-card>
    </div>
</div>

