    <form [formGroup]="passwordResetForm">
        @if(passwordResetTries > 0){
        <div class="wrong-password">
            Please try a different password
            <br />
            @if(timeOutSeconds > 0){
                <div> Exceeded max number of login attempts, wait {{ timeOutSeconds }}
                    seconds
                </div>
            }
        </div>
        }

        <div class="login-form-control" style="display: flex; flex-direction: column;">
            <mat-form-field class="full-width"  [style.width.%]="100">
                <div style="display:flex">
                    <div style="width:100%;">
                        <input matInput [type]="passwordType$ | async" formControlName="password" #password id="password" placeholder="Password">
                    </div>
                    <div>
                        <lib-svg-icon [color]="'darkGrey'" [iconName]="password_icon$ | async" [size]="'small'" (click)="showPasswordClicked()" ></lib-svg-icon>
                    </div>
                </div>
            </mat-form-field>
        </div>

   
        <div style="flex:1; margin:auto;">
            <div class="onboarding-next-button-holder">
                <button
                mat-raised-button
                  [disabled]="!isValid$.value"
                  (click)="clickedSubmit(passwordResetForm.value)"
                  class="onboarding-action-button primary-font"                >
                Submit
              </button>
            </div>
        </div>
    </form>
