import {Component, Inject,inject}from '@angular/core';
import { Router } from '@angular/router'

import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

import {FormBuilder, ReactiveFormsModule} from "@angular/forms";
import { AppStatusStore } from '@sybl/core-state';
import { AuthStatusStore } from '@sybl/feature-auth-state';
import { AuthBackendService } from '@sybl/feature-auth-data-access';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserProfileStore } from '@sybl/feature-users-state';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-logout-dialog',
  standalone: true,
  providers: [AuthBackendService],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatProgressSpinnerModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule ],

  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent  {
  readonly appStore = inject(AppStatusStore);
  readonly authStore = inject(AuthStatusStore);
  readonly userProfileStore = inject(UserProfileStore);

  get username(){ if(this.authStore.email()){return  this.authStore.email().split('@')[0]}else return '' }
  get usernameInitial() { if (this.username !== undefined) return this.username.substring(0, 1).toUpperCase(); else return '' }
  get avatar(){ return this.userProfileStore.avatar()}
  get permissions(){return this.authStore.permissions()}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private dialogRef: MatDialogRef<LogoutDialogComponent>,
    private router: Router,
    public fb: FormBuilder,
    private authBackendService: AuthBackendService
  ){
    //this.inTimeOut$ = this.store.select(fromAuth.getInTimeOut)

  }

  logout(){
    this.authBackendService.logoutUser()
    this.authStore.logoutUser()
    this.router.navigate([''])

    //close dialog and sidenav menu.
    this.appStore.closeRightSidenav();
    this.dialogRef.close();
  }

  cancel(){

    this.dialogRef.close();
  }

}
