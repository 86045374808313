import {
  Component,
  inject,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthPasswordStore } from '@sybl/feature-auth-state';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PasswordBackendService } from '@sybl/feature-auth-data-access';
import { IAuthResponse, IAuth } from '@sybl/feature-auth-models';

import { Router } from '@angular/router';

@Component({
  selector: 'lib-forgot-password-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
  ],

  templateUrl: `./forgot-password-form.html`,
  styleUrls: ['./forgot-password-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordFormComponent {
  readonly store = inject(AuthPasswordStore);

  get timeOutSeconds() {
    return this.store.timeOutSeconds();
  }
  get passwordResetTries() {
    return this.store.passwordAttempts();
  }
  get inTimeOut() {
    return this.store.passwordInTimeOut();
  }
  get loading() {
    return this.store.passwordLoading();
  }

  forgotPasswordLockUrl: string;
  forgotPasswordForm: FormGroup;

  constructor(
    @Inject('appUrls') private appUrls: any, // EnvironmentAppUrls ,
    private fb: FormBuilder,
    private passwordService: PasswordBackendService,
    private router: Router
  ) {
    this. forgotPasswordLockUrl = this.appUrls.IMAGES_URL + 'forgotPasswordLock.svg';
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(), //['', Validators.required]
    });
  }

  clickedSubmit(emailForm:any) {
    // Forgot password takes an email or username and fires off a ForgotPasswordAttempt,
    // which tracks the number of times the form has been submitted
    // an Observable inTimeOut$ checks the store to see if the Submit Button for auth should be disabled.
    const email = emailForm.email;
    // Do not submit unless there is text, should put protections in the types...
    if (emailForm.email.length > 0) {
       // this.authService.forgotPasswordAttempt({email})
      this.passwordService
        .forgotPasswordCheck(email, this.inTimeOut)
        .subscribe((authResponse: IAuthResponse) => {

          if (authResponse.user !== undefined && authResponse.user !== null) {
            const userModel: IAuth = authResponse.user;

           this.store.setForgotPass(authResponse.user.email, authResponse.user.username);
           
            return this.router.navigate(['/authorization/forgot-password-confirmed']);
            //  return this.store.dispatch(
            //     AuthResetPasswordActions.ForgotPasswordConfirmed({ user: userModel })
            // );
          } return null// else {
          //  return this.store.dispatch(AuthResetPasswordActions.ForgotPasswordFailed());
          // }
        });
    }
  }
}
