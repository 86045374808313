import { 
     Component, OnInit, OnDestroy, ChangeDetectionStrategy, Inject, inject, PLATFORM_ID
} from '@angular/core';
import { isPlatformBrowser, CommonModule } from '@angular/common';

import {
    Router, 
    ParamMap, 
    ActivatedRoute    
} from  '@angular/router';

import { Subscription, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators';
import { AuthPasswordStore } from '@sybl/feature-auth-state'
import { PasswordResetFormComponent } from './password-reset-form.component'
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import { PasswordBackendService } from '@sybl/feature-auth-data-access';
import { IAuthError, IAuthResponse } from '@sybl/feature-auth-models';
import { MatButtonModule } from '@angular/material/button';


@Component({
  selector: 'lib-password-reset',
  standalone: true,
  imports: [CommonModule, MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule, PasswordResetFormComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'password-reset.component.html',
  styleUrls: ['password-reset.scss']    
})
export class PasswordResetComponent implements OnInit, OnDestroy{
    readonly store = inject(AuthPasswordStore);
    forgotPasswordExpiredUrl: string;

    private isBrowser = false;
    private _onDestroy = new Subject();
    
    resetToken$ = new Subscription(); //Pulled from url

    get forgotPasswordAvatar(){ return this.store.forgotPasswordAvatar() }
    get passwordResetToken(){ return this.store.passwordResetToken() }
    get forgotPasswordErrors(){ return this.store.forgotPasswordErrors()}
    get loading(){ return this.store.passwordLoading() }
    get forgotPasswordUsername(){ return this.store.forgotPasswordUsername() }
    get forgotPasswordEmail(){ return this.store.forgotPasswordEmail() }
    
    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        @Inject('appUrls') private appUrls: any,//EnvironmentAppUrls,
        private route: ActivatedRoute,
        private router: Router,
        private passwordService: PasswordBackendService
        ) { 
            
            this.forgotPasswordExpiredUrl = appUrls.IMAGES_URL + "forgotPasswordExpired.svg"
            this.isBrowser = isPlatformBrowser(this.platformId) ? true : false;
        }
        
        ngOnInit() {
            
            if(this.isBrowser && typeof window !=='undefined'){
                this.resetToken$ = this.route.paramMap.pipe(
                    map((params: ParamMap) =>{
                    const token = params.get('token')!;
                    this.store.setTempPassToken(token)

                    
                    this.passwordService
                    .checkToken(token)
                    .subscribe((authResponse: IAuthResponse | any) => {
                        if(authResponse.user !== undefined && authResponse.user !== null){
                            
                            this.store.setForgotPass(authResponse.user.email, authResponse.user.username)
                            
                            
                        }else{

                            const error: IAuthError = {
                                tries: 0,
                                authAttempt: 'password',
                                lockUntil: null,
                                message: authResponse.error,
                                wrongPassword: false
                              }

                              
                            this.store.setForgotPasswordErrors(error)
                        }

                    })

                }),
                takeUntil(this._onDestroy)
                ).subscribe()
            }
        }

        navigateTo():void{
            this.router.navigate(['/authorization/forgot-password'])
        }
    
        ngOnDestroy(){
            this._onDestroy.next(true)
        }
    
}
