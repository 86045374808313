<div class="forgot-password-container" fxLayoutAlign="center center">
    <mat-card class="forgot-pass-mat">

        <div class="password-icon-holder">
            <img class="email-icon" src="{{forgotPasswordExpiredUrl}}">
        </div>
        
        <div class="forgot-password-expired-container">
            <div>
                <span>
                    The Password Reset Token you've submitted is no longer valid.
                </span>
                <br/>
                <p></p>
                <span class="hyperlink-always-blue" (click)="forgotPassword()">Click To request a New Token</span>
            </div>

            <br/>
            <br/> Follow the instructions in the email and log back in.

        </div>





    </mat-card>
</div>