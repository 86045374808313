/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Injectable } from '@angular/core';
import { Observable, timer, of } from 'rxjs';

import { switchMap, map } from 'rxjs/operators';

import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { AuthBackendService } from '../services';

//const validCharacters = new RegExp('^[^!<>?=+@{}_$%]*[0-9]+[^!<>?=+@{}_$%]*$');

@Injectable()
export class UsernameValidators {
  constructor(private authService: AuthBackendService) {}

  checkUsername(username: string) {
    // Could use
    return timer(500).pipe(
      switchMap(() => {
        // Check if username is available
        return this.authService.checkUsername(username);
      })
    );
  }

  usernameValidator(): AsyncValidatorFn {
    // Could use
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {

      return (
        this.checkUsername(control.value)
          // Check if username is available
          .pipe(
            map((res) => {

              if (res.status === 'bad') {
                return { usernameUnavailable: true };
              }
              return null;
            })
          )
      );
    };
  }

  passwordValidator(props): AsyncValidatorFn {
    // Could use
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      return (
        this.checkPassword(control.value, props)
        // Check if username is available
        );
    };
  }
  
  checkPassword(password: string, props) {
    // Could use
    return timer(750).pipe(
      map(() => {
        // Check if username is available
        if(password.length< props.minLength){
          return {minLength:true}
        }else return null

        

      })
    );
  }

  emailValidator(props): AsyncValidatorFn {
    // Could use
    return (
      control: AbstractControl
    ): Observable<{ [key: string]: any } | null> => {
      return (
        this.checkEmail(control.value, props)
        // Check if username is available
        .pipe(
          //@ts-expect-error
          map((res) => {
            
            if(res.invalidEmail===true){
              return {invalidEmail:true}
               
            }
            else if (res.status === 'bad') {
              return { emailUnavailable: true };
            }
          })
          )
      );
    };
  }

  
  checkEmail(email: string, props) {
    // Could use
    return timer(500).pipe(
      switchMap(() => {
        // Make sure it folllows email address format

        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){
          // If valid email, maake sure available.
          return this.authService.checkEmail(email)
        }else{

          return of({invalidEmail:true})
        }
         
        // Check if username is available
      })
    );
  }



  checkEmaiOld(email: string, props) {
    // Could use a service to look up if email l
    return timer(750).pipe(
      map(() => {
        // Check if email is valid
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)){
          return null
          
        }else{
          return {invalidEmail:true}
        }
         

      })
    );
  }
}