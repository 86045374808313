import { Route } from "@angular/router";
import { AuthBackendService } from '@sybl/feature-auth-data-access'
import { 
    SignUpSmartComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordConfirmedComponent,
    PasswordResetComponent,
    ChangePasswordComponent,
    ForgotPasswordExpiredComponent,
    PasswordChangedComponent,

} from './pages'
import { ScrollService } from "@sybl/common-services";

export const AUTH_ROUTES: Route[] = [
    {
      path: '',
      providers: [
        AuthBackendService,
        ScrollService
        // Can Provide Services to all components in this route....
       // {provide: ADMIN_API_KEY, useValue: '12345'},
      ],
      children: [
        {
            path: 'signup/:referer',
            component: SignUpSmartComponent,
            pathMatch: 'full'
          },
          {
            path: 'signup',
            component: SignUpSmartComponent
          },
          {
            path: 'forgot-password',
            component: ForgotPasswordFormComponent
          },
          {
            path: 'forgot-password-confirmed',
            component: ForgotPasswordConfirmedComponent
          },
          {
            path: 'password-reset/:token',
            component: PasswordResetComponent,
            //resolve: { passwordResetUserInfo: TokenValidatorResolver }
          },
          {
            path: 'change-password',
            component: ChangePasswordComponent
          },
          {
            path: 'forgot-password-expired',
            component: ForgotPasswordExpiredComponent
          },
          {
            path: 'password-changed',
            component: PasswordChangedComponent
          }
      ],
    },
    // ... other application routes that don't
    //     have access to ADMIN_API_KEY or AdminService.
  ];

