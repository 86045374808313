import { Component, Inject, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import { AuthStatusStore, AuthPasswordStore } from '@sybl/feature-auth-state';
import { MatCardModule } from '@angular/material/card';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-forgot-password-confirmed',
  standalone: true,
  imports: [CommonModule, MatCardModule],
  templateUrl: './forgot-password-confirmed.html',
  styleUrls: ['./forgot-password-confirmed.scss'],
})
export class ForgotPasswordConfirmedComponent {
    readonly store = inject(AuthStatusStore);
    readonly passwordStore = inject(AuthPasswordStore);


  get username(){ return  this.store.email().split('@')[0] }
  get user_id(){ return this.store.user_id() }

  get emailSubmitted() { return this.passwordStore.emailSubmitted()}
  get forgotPasswordUsername() { return this.passwordStore.forgotPasswordUsername()}
  get forgotPasswordEmail() { return this.passwordStore.forgotPasswordEmail()}

  emailConfirmedUrl: string;

  constructor(
    @Inject('appUrls') appUrls: any //EnvironmentAppUrls ,
  ) {
    this.emailConfirmedUrl = appUrls.IMAGES_URL + 'emailConfirmed.svg';
  }
}
