import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import { Router } from '@angular/router'

@Component({
    standalone: true,
    imports: [CommonModule, MatCardModule],
    selector: 'lib-forgot-password-expired',
    templateUrl: 'forgot-password-expired.html',
    styleUrls: ['forgot-password-form.scss']
})
export class ForgotPasswordExpiredComponent{
    forgotPasswordExpiredUrl: string;

    constructor(
        private router: Router,
        @Inject('appUrls') appUrls: any //EnvironmentAppUrls 
    ){
        this.forgotPasswordExpiredUrl = appUrls.IMAGES_URL + "forgotPasswordExpired.svg"
    }

    forgotPassword(){

        this.router.navigate(['/authorization/forgot-password'])
    }
}