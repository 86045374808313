import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import {
  FormBuilder,
} from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  // tslint:disable-next-line: component-selector
  selector: 'lib-simple-text-dialog',
  templateUrl: './simple-text-dialog.component.html',
  styleUrls: ['./simple-text-dialog.component.scss'],
})
export class SimpleTextDialogComponent {
  text: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { text: string },
    @Inject('appUrls') appUrls: any,
    private dialogRef: MatDialogRef<SimpleTextDialogComponent>,
    public fb: FormBuilder,
    private router: Router
  ) {
    this.text = data.text;
  }

  clickedSaved() {
    this.dialogRef.close({ msg: 'saved' });
  }

  close() {
    this.dialogRef.close({ msg: 'saved' });

    //this.appFacade.saveAcknowledged()
  }
}
