import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import {
  Observable,
  of,
  BehaviorSubject,
  Subject,
  throwError as observableThrowError
} from 'rxjs';

//import { contentHeaders } from '@state-of-comedy/common/models';

import { IAuth, IAuthResponse, IPasswordReset, IPasswordChangeParams, IAuthError } from '@sybl/feature-auth-models';

interface IForgotPasswordResponse {
  username: string,
  email: string,
  avatar: string
}


@Injectable()
export class PasswordBackendService { 
  
  private userServer: string;
  
  private changePasswordUrl;
  private forgotPasswordUrl;
  private forgotPasswordResetUrl;
  private createPasswordUrl;

  // appUrls is set in app.environment
  constructor(
    @Inject('appUrls') private appUrls: any,
    private http: HttpClient,
    private router: Router,
  ) {

    this.userServer = this.appUrls.AUTH_URL;
   
    this.forgotPasswordUrl = this.userServer + '/auth/forgot-password';
    this.forgotPasswordResetUrl = this.userServer  + '/auth/forgot-password-reset';
    this.createPasswordUrl = this.userServer  + '/auth/validate/create-password';

    this.changePasswordUrl = this.userServer + '/auth/change-password';
}




  forgotPasswordCheck(emailOrUserName: string, inTimeOut:any):Observable<IAuthResponse> {    
    const params = new HttpParams().set('email', emailOrUserName);

    const options = {params: new HttpParams().set('email', emailOrUserName) }
   if (!inTimeOut) {

      return (
        this.http
          .get<IAuthResponse>(this.forgotPasswordUrl, options)
          //return this.http.get(this.nflStadiumUrl, { params: params, headers: contentHeaders, withCredentials: true })            
      );
    } else {
      // Need to get lockUntil Date from server?


      const error:IAuthError ={
        authAttempt: 'forgotPassword',
        wrongPassword: true,
        lockUntil: inTimeOut.lockUntil,
        tries: inTimeOut.tries,
        message:'Error'
      } 
      
      const authResponse = {
        user: null,
        error: error
      }

      return of( 
        authResponse
      );
    } 

  }

  // Second part of forgotPassword, after confirming the user does exist post to server the email to send paswsord reset.
  forgotPasswordSubmit(email: string) {
    const params = new HttpParams().set('email', email);

    return this.http.post(this.forgotPasswordUrl, params).pipe(
      map(response => {
        return response;
      }),
      catchError(err => {
        return err;
      })
    );
  }

  //Change to changePasword instead of reset
  forgotPasswordReset(details: IPasswordReset) {
    const body = {
      email: details.email,
      token: details.token,
      password: details.password,
    };
// headers: contentHeaders,
    return this.http
      .post(this.forgotPasswordResetUrl + '/' + details.token, body, {
        withCredentials: true
      })
      .pipe(
        map((res: IAuthResponse | any) => {
          return res;
        }),
        catchError(err => {
          return err;
        })
      );
  }

  createPassword(details: IPasswordReset, firstName?, lastName?) {
    const body = {
      email: details.email,
      token: details.token,
      password: details.password,
      firstName: firstName,
      lastName: lastName,
    };
// headers: contentHeaders,
    return this.http
      .post(this.createPasswordUrl + '/' + details.token, body, {
        withCredentials: true
      })
      .pipe(
        map((res: IAuthResponse | any) => {
          return res;
        }),
        catchError(err => {
          return err;
        })
      );
  }

  checkToken(prop:any) {
    const token = prop;

    return this.http.get(this.forgotPasswordResetUrl + '/'+ token)
        .pipe(
            map(response => {
                return response
            }),
            catchError((err) => {
                console.warn('err in check token', err)
                return err
            })
        )
  }

  changePassword(passwordParams: IPasswordChangeParams) {
    const body = {
        user_id: passwordParams.user_id,
        password: passwordParams.password,
        currentPassword: passwordParams.currentPassword,
        jwToken: passwordParams.jwToken,
    }

    // headers: contentHeaders,
    return this.http.post(this.changePasswordUrl, body, {  withCredentials: true })
        .pipe(
            map((res: any) => {

                return res;
            }),
            catchError((err) => {
                return err
            })

        )
}

  
}