<div class="smart-page-holder circle-background-images"> 
  <div
  style="display: flex; flex-direction: column; width: 100%; justify-content: center; margin-top: 76px;"
    class="forgot-password-container"
    fxLayoutAlign="center center"
  >
    <mat-card class="forgot-pass-mat">
      <div class="password-icon-holder">
        <img class="email-icon" src="{{emailConfirmedUrl}}" />
      </div>

      <div class="forgot-password-confirmed-text">
        @if(emailSubmitted ===true){
        <div>
          An Email has been sent to

          <br />
          <br />

          <span class="email-submitted"> {{forgotPasswordEmail }} </span>
        </div>
        } @if(emailSubmitted ===false){
        <div>
          An Email has been sent to
          <br />
          the email address for

          <br />
          <br />

          <span class="email-submitted"> {{forgotPasswordUsername}} </span>
        </div>
        }
        <br />
        <br />

        Follow the received <br />
        instructions to log back in.
      </div>
    </mat-card>
  </div>
</div>
