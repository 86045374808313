<div class="smart-page-holder circle-background-images"> 
  <div
  style="display: flex; flex-direction: column; width: 100%; justify-content: center; margin-top: 76px;"
  class="password-changed-container"
    fxLayoutAlign="center center"
  >
    <mat-card class="pass-mat">
      @if(passwordChanged===true){
      <div>
        @if(loading === true){
        <div class="data-loading-spinner">
          <mat-spinner style="margin: auto" color="primary"> </mat-spinner>
        </div>
        }
        <div class="password-icon-holder">
          <img class="email-icon" src="{{ passwordChangedUrl }}" />
        </div>
        <div class="text-side-container">
          The Credentials for
          <span class="email-submitted">
            {{ username }}
          </span>
          have been updated and a confirmation email has been sent to
          <br />
          <span class="email-submitted">
            {{ email }}
          </span>
        </div>
      </div>
      } @if(passwordChanged === false){
      <div class="text-side-container">
        <br />

        @if(avatar===undefined && permissions >= 3){
          <div class="logout-avatar-holder ">
            <div
              style="
                background-color: whitesmoke;
                border-radius: 63px;
                height: 123px;
                width: 123px;
                display: flex;
                margin-bottom: 1em;
              "
            >
              <div style="color: #ce6925; 
                  margin: auto;
                  font-size: 6em;
                  font-weight: 600;
              ">
                {{ usernameInitial }}
              </div>
            </div>
          </div>
          } @else if(avatar !== undefined && permissions >= 3){
              <div class="logout-avatar-holder">
                  <img class="logout-avatar" [src]="avatar">
              </div>
          } 

        <span style="font-weight: 500; font-size: 1.1em;" (click)="changePassword()">
          
          Password has been changed for
      </span>
      <br/>
        <div class="username-box">
          {{ email }}
          <br/>
        </div>
      </div>
      }
      <div class="home-container">
        <div class="home-hyperlink" style="font-size: 1.2em;" (click)="navigateHome()">
          Click to go home
        </div>
      </div>
    </mat-card>
  </div>
</div>
