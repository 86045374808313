/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Component,
  inject,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule, Location } from '@angular/common';

import {
  Observable,
  BehaviorSubject,
  Subscription,
  combineLatest,
  Subject,
} from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

import {
  PasswordValidators,
  AuthBackendService,
} from '@sybl/feature-auth-data-access';
import { AuthStatusStore } from '@sybl/feature-auth-state';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Must import For AsyncValidation
import {
  MatFormErrorComponent,
  MatFormErrorStateMatcher,
  SimpleTextDialogComponent,
} from '@sybl/common-ui';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { SvgIconComponent } from '@sybl/feature-custom-elements-ui';
import { Router } from '@angular/router';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'lib-change-password-form',
  standalone: true,
  providers: [AuthBackendService],
  imports: [
    CommonModule,
    MatButtonModule,
    SvgIconComponent,
    MatProgressSpinnerModule,
    MatDialogModule,
    SimpleTextDialogComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatFormErrorComponent,
  ],
  templateUrl: `./change-password-form.component.html`,
  styleUrls: ['./change-password.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangePasswordFormComponent implements OnInit, OnDestroy {
  readonly store = inject(AuthStatusStore);
  authBackendService = inject(AuthBackendService);

  get passwordResetTries() {
    return this.passwordResetTries();
  }
  get inTimeOut() {
    return this.store.inTimeOut();
  }
  get timeOutSeconds() {
    return this.store.timeOutSeconds();
  }

  matcher = new MatFormErrorStateMatcher();

  @Input() email!: string;
  @Input() user_id!: string;
  @Input() jwToken: string;
  @Input() loading!: boolean;

  currentPassword$!: Observable<any>;
  password$!: Observable<any>;
  confirmPass$!: Observable<any>;
  validFormObservable$ = new Subscription();
  showPassword$ = new BehaviorSubject<boolean>(false);
  password_icon$ = new BehaviorSubject<string>('password_hide.svg');
  passwordType$ = new BehaviorSubject<string>('password');
  showConfirmPassword$ = new BehaviorSubject<boolean>(false);

  public formErrors = {
    currentPassword: '',
    password: '',
    confirmPass: '',
  };

  isValid$ = new BehaviorSubject<boolean>(false);
  private _onDestroy = new Subject();
  changePasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private location: Location,
    private router: Router,
    private validatePassword: PasswordValidators,
  ) {
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl(
        null,
        [Validators.required], //
        this.validatePassword.currentPasswordValidator(),
      ),
      password: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9!@#$%^&*()]+$/),
        Validators.maxLength(50),
      ]),
      confirmPass: new FormControl(
        null,
        //[this.validatePassword.confirmPasswordValidator()]
      ),
    });

    this.changePasswordForm.statusChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((status) => {});
  }

  ngOnInit() {
    // This appears to be necessary
    this.currentPassword$ = this.changePasswordForm
      .get('currentPassword')!
      .valueChanges.pipe(takeUntil(this._onDestroy));
    this.password$ = this.changePasswordForm
      .get('password')!
      .valueChanges.pipe(takeUntil(this._onDestroy));
    this.confirmPass$ = this.changePasswordForm
      .get('confirmPass')!
      .valueChanges.pipe(takeUntil(this._onDestroy));

    this.validFormObservable$ = combineLatest([
      this.currentPassword$,
      this.password$,
      this.confirmPass$,
    ])
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map(([one, two, three]) => {
          if (two === three) {
            this.isValid$.next(true);
            return true;
          } else {
            this.isValid$.next(false);
            return false;
          }
        }),
        takeUntil(this._onDestroy),
      )
      .subscribe();
  }

  get currentPassword() {
    return this.changePasswordForm.get('currentPassword');
  }

  get password() {
    return this.changePasswordForm.get('password');
  }

  get confirmPass() {
    return this.changePasswordForm.get('confirmPass');
  }

  clickedSubmit(passwordForm: any) {
    const password = passwordForm.password;
    const currentPassword = passwordForm.currentPassword;
    const user_id = this.user_id;
    const jwToken = this.jwToken;
    // Forgot password takes an email or username and fires off a ForgotPasswordAttempt,
    // which tracks the number of times the form has been submitted
    // an Observable inTimeOut$ checks the store to see if the Submit Button for auth should be disabled.
    //['', Validators.required],

    if (this.changePasswordForm.valid) {
      this.authBackendService
        .changePassword(user_id, password, jwToken, currentPassword)
        .subscribe((response: any) => {
          if (response.user !== undefined) {
            const dialog = this.dialog.open(SimpleTextDialogComponent, {
              width: '300px',
              data: {
                text: 'Updated Password',
              },
            });

            dialog.afterClosed().subscribe((result) => {
              return this.location.back();
            });
          } else {
            this.dialog.open(SimpleTextDialogComponent, {
              width: '300px',
              data: {
                text: 'Error Saving',
              },
            });
          }
        });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next(true);
  }

  showPasswordClicked() {
    this.showPassword$.next(!this.showPassword$.value);
    if (this.showPassword$.value === true) {
      this.password_icon$.next('password_show.svg');
      this.passwordType$.next('text');
    } else {
      this.password_icon$.next('password_hide.svg');
      this.passwordType$.next('password');
    }
  }

  showConfirmPasswordClicked() {
    this.showConfirmPassword$.next(!this.showConfirmPassword$.value);
    if (this.showConfirmPassword$.value === true) {
      this.password_icon$.next('password_show.svg');
      this.passwordType$.next('text');
    } else {
      this.password_icon$.next('password_hide.svg');
      this.passwordType$.next('password');
    }
  }
}
