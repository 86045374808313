<div class="smart-page-holder circle-background-images"> 

    <div style="display:flex; flex-direction:column;" class="forgot-password-container" fxLayoutAlign="center center">
        <mat-card class="forgot-pass-mat">

            <!-- Forgot Password Icon -->
            <div class="password-icon-holder">
                <img class="email-icon" src="{{forgotPasswordLockUrl}}">
            </div>

            <div class="forgot-password-form">
                    @if(loading === true){
                        <div class="data-loading-spinner">
                            <mat-spinner style="margin:auto" color="primary">
                            </mat-spinner>
                        </div>
                    }

                    @if(passwordResetTries > 0){
                    <div class="wrong-password">
                        Username or Email does not exist.
                        <br/>

                        @if(timeOutSeconds > 0){
                            <div > Exceeded max number of login attempts, wait {{ timeOutSeconds }} seconds</div>
                        }
                    </div>
                }
                    <form [formGroup]="forgotPasswordForm">

                        <div style="text-align:center; margin-bottom: .5em;">
                            <div class="forgot-password-title"> Forgot Password?</div>
                        </div>
                    
                        <div style="text-align:justify; margin-bottom:.5em">
                            Enter your username or email used to login, and we will send you an email with details to reset password.
                        </div>

                        <div style="flex:1; margin-right: 1em; width:100%">
                            <mat-form-field style="width:100%">
                                <input matInput type="text" formControlName="email" placeholder="Email or Username">
                            </mat-form-field>
                        </div>

                        <div style=" display: flex; justify-content: center; margin:auto;">
                            <button mat-button class="onboarding-action-button primary-font" [disabled]="inTimeOut" (click)="clickedSubmit(forgotPasswordForm.value)">Submit</button>
                        </div>
                    </form>
            </div>
        </mat-card>
    </div>
</div>