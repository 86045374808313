<div style="min-height: 190px">
  <div style="min-height: 170px">
    <mat-dialog-content>
      <div style="margin-top: 16px">
        <div
          style="
            font-size: 1.2em;
            font-weight: 500;
            color: #2b2b2b;
            margin: 0 auto 1em auto;
            text-align: center;
          "
        >
          {{ text }}
        </div>
      </div>
      <div style="display: flex; flex-direction: column">
        <button
          mat-raised-button
          style="
            margin: auto;
            display: flex;
            width:200px;
            font-size: 1.1em;
            background-color: #ce6925;
            color: ivory;
          "
          (click)="clickedSaved()"
          class="btn btn-default"
        >
          Continue
        </button>
      </div>
    </mat-dialog-content>
  </div>
</div>
