import {
    Component,
    OnInit,
    Input,
    OnDestroy,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    inject
} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ReactiveFormsModule } from "@angular/forms";

import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators'

import { PasswordBackendService, PasswordFormService } from '@sybl/feature-auth-data-access'
import { AuthPasswordStore, AuthStatusStore } from '@sybl/feature-auth-state';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SvgIconComponent } from '@sybl/feature-custom-elements-ui'
import { Router } from '@angular/router';


@Component({
    selector: 'lib-password-reset-form',
    standalone: true,
    imports: [CommonModule, MatButtonModule, SvgIconComponent, ReactiveFormsModule, MatInputModule, MatFormFieldModule ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './password-reset-form.component.html',
    styleUrls: ['./password-reset.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PasswordResetFormComponent implements OnInit, OnDestroy {
    readonly store = inject(AuthPasswordStore);
    readonly authStatusStore = inject(AuthStatusStore);

    validForm$ = new BehaviorSubject<boolean>(false);
    validFormObservable$ = new Subscription()
    isValid$ = new BehaviorSubject<boolean>(false);

    get passwordResetTries() { return this.store.passwordAttempts()}
    get inTimeOut() { return this.store.passwordInTimeOut()}
    get timeOutSeconds() { return this.store.timeOutSeconds()}
    get getPasswordErrors() { return this.store.passwordErrors()}
    get getConfirmPasswordErrors() { return this.store.confirmPasswordErrors()}

    
    showPassword$ = new BehaviorSubject<boolean>(false);
    password_icon$ = new BehaviorSubject<string>('password_hide.svg')
    passwordType$ = new BehaviorSubject<string>('password');
    public passwordResetForm: FormGroup;
    //Do not like having all of these observables for this...
    passwordStatus$!: Observable<boolean>
    confirmPassStatus$!: Observable<boolean>
    password_hide
    password_show

    public formErrors = {
        password: '',
        confirmPass: '',
    };

    private _onDestroy = new Subject();

    @Input() token: any;
    @Input() passwordResetToken!: string;
    @Input() passwordResetUsername!: string;
    @Input() passwordResetEmail!: string;


    constructor(
        @Inject('appUrls') appUrls: any,
        private fb: FormBuilder,
        private passwordFormValidatorService: PasswordFormService,
        private passwordService: PasswordBackendService,
        private router: Router
    ) {
        this.password_hide = appUrls['IMAGES_URL'] + 'password_hide.svg'
        this.password_show = appUrls['IMAGES_URL'] + 'password_show.svg'

        this.passwordResetForm = this.fb.group({
            password: new FormControl('', [Validators.required, Validators.minLength(5)]),
        });

    }
    // convenience getter for easy access to form fields
    get f() { return this.passwordResetForm.controls; }

    showPasswordClicked() {
        this.showPassword$.next(!this.showPassword$.value);
        if (this.showPassword$.value === true) {
            this.password_icon$.next('password_show.svg')
            this.passwordType$.next('text')
        } else {
            this.password_icon$.next('password_hide.svg')
            this.passwordType$.next('password')
        }
    }

    ngOnInit() {

        this.passwordResetForm.statusChanges.
          pipe(
            takeUntil(this._onDestroy)
          ).subscribe((status) => {

            if (status === 'INVALID') {
              this.isValid$.next(false)
            } else if (status === 'VALID') {
              this.isValid$.next(true)
            }
          })
    

  
        this.passwordStatus$ = this.passwordResetForm.get('password')!.valueChanges.pipe(takeUntil(this._onDestroy))


    }

      clickedSubmit(passwordForm: any) {
        // Forgot password takes an email or username and fires off a ForgotPasswordAttempt, 
        // which tracks the number of times the form has been submitted 
        // an Observable inTimeOut$ checks the store to see if the Submit Button for auth should be disabled.
        //['', Validators.required],
        this.passwordFormValidatorService.markFormGroupTouched(this.passwordResetForm)//, this.formErrors, false);

        const token = this.passwordResetToken;
        const email = this.passwordResetEmail;
        const password = passwordForm.password;
        // Do not submit unless there is text, should put protections in the types...
        if (passwordForm.password.length > 5) {

           this.passwordService.forgotPasswordReset({token, email, password}).subscribe(res=>{

            if(res.user){
                this.passwordResetForm.get('password')?.disable();
                const password =  this.passwordResetForm.get('password').value;

                this.authStatusStore.login(this.passwordResetEmail, password)

                return this.router.navigate(['/authorization/password-changed']);

            }return null
           })
        } else {
            return
        }

    }

    ngOnDestroy() {
        this._onDestroy.next(true)
    }


}
