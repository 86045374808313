import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AuthStatusStore } from '@sybl/feature-auth-state';
import { ChangePasswordFormComponent } from './change-password-form.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  standalone: true,
  imports: [CommonModule, ChangePasswordFormComponent, MatCardModule],
  selector: 'lib-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent {
  readonly store = inject(AuthStatusStore);

  get avatar() {
    return this.store.avatar();
  }
  get loading() {
    return this.store.loading();
  }
  get username() {
    return this.store.username();
  }
  get permissions(){return this.store.permissions()}

  
  get user_id() {
    return this.store.user_id();
  }
  get email() {
    return this.store.email();
  }
  get usernameInitial() { if (this.email !== undefined) return this.email.substring(0, 1).toUpperCase(); else return '' }
  get jwToken() {
    return this.store.jwtToken();
  }
}
