/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Inject, effect, inject, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import {MatButtonModule} from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogModule } from "@angular/material/dialog";
import {MatInputModule} from '@angular/material/input';
import {FormBuilder, Validators, FormGroup, FormControl, ReactiveFormsModule} from "@angular/forms";
import { Router } from '@angular/router'
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { getState } from '@ngrx/signals';
import { Subscription, Subject, Observable, BehaviorSubject } from 'rxjs';
import { SvgIconComponent } from '@sybl/feature-custom-elements-ui'
import { AuthPasswordStore, AuthStatusStore } from '@sybl/feature-auth-state'
import { AuthBackendService } from "@sybl/feature-auth-data-access";

@Component({
    selector: 'lib-login-dialog',
    standalone: true,
    providers: [AuthBackendService],
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatProgressSpinnerModule, ReactiveFormsModule, SvgIconComponent, MatInputModule, MatFormFieldModule ],
    templateUrl: './login-dialog.component.html',
    styleUrl: './login-dialog.component.scss',
  })
  export class LoginDialogComponent implements OnInit, OnDestroy {
        get user_id(){ return this.store.user_id()}
        loggedIn!:boolean;
        logInSubject = new Subject();
        routeToUrl = '';
        _onDestroy = new Subject();
        showPassword$ = new BehaviorSubject<boolean>(false);
        password_icon$ = new BehaviorSubject<string>('password_hide.svg')
        passwordType$ = new BehaviorSubject<string>('password');


        get timeOutSeconds(){ return this.store.timeOutSeconds() }
        get loading(){ return this.store.loading() }
        get wrongPassword(){ return this.store.wrongPassword() }

        loginCheck$ = new Subscription();
        
        loginForm!: FormGroup
        avatarUrl!:string;
        avatar:string;
        password_show: string;
        password_hide: string;
        
        showAction = ''
        readonly store = inject(AuthStatusStore);
        readonly passwordStore = inject(AuthPasswordStore);
        
        constructor(
            @Inject(MAT_DIALOG_DATA) public data:{referer:string, routeToUrl:string},
            @Inject('appUrls') appUrls:any,
            
            private dialogRef: MatDialogRef<LoginDialogComponent>,
            //private authService: AuthFacade,
            public fb: FormBuilder,
            private router: Router
            ){
                this.avatar =  appUrls['AVATAR_URL'] + appUrls['DEFAULT_AVATAR']
                this.password_hide = appUrls['IMAGES_URL'] + 'password_hide.svg'
                this.password_show = appUrls['IMAGES_URL'] + 'password_show.svg'
                this.loginForm = fb.group({
                    email: new FormControl('', Validators.required),
                    password: new FormControl('', Validators.required)
                })
                effect(() => {
                    // 👇 The effect will be re-executed whenever the state changes.
                    const state = getState(this.store);


                    if(state.user_id !== undefined && state.user_id !== "" && state.user_id !== null && state.permissions>1){
                        this.dialogRef.close();
                    }
                  });

  
        }
    
    
    
        ngOnInit(){ 


            //this.loggingIn$ = this.store.select(fromAuth.getLoading)
            if(this.data!== null){
    
                const referer = this.data.referer;
                const routeToUrl = this.data.routeToUrl;
                this.routeToUrl = routeToUrl;
                switch(referer){
                    case 'vote': this.showAction = 'Sign In To Vote'; break;
                    case 'read': this.showAction = 'Sign In To Mark Read'; break;
                    case 'favorite': this.showAction = 'Sign In To Favorite'; break;

                    default: break;
                }
            }
        }
    
        ngOnDestroy(){
            this._onDestroy.next(true)
        }
    
        showPasswordClicked(){
            this.showPassword$.next(!this.showPassword$.value);
            if(this.showPassword$.value===true){
                this.password_icon$.next('password_show.svg')
                this.passwordType$.next('text')
            }else 
                {
                   this.password_icon$.next('password_hide.svg')
                   this.passwordType$.next('password')
                }
        }
    
        submitLogin():void {
            //Have required validators on the fields will not submit if they are empty
            if(this.loginForm.status === 'VALID'){ 
    
                const email= this.loginForm.get('email')!.value;
    
                const password = this.loginForm.get('password')!.value;
              
                this.store.login(email, password).then(response=>{
                    //
                })
            }
        }
    
        clickedForgotPassword(){
            this.router.navigate(['/authorization/forgot-password'])
            this.dialogRef.close()
        }
    
        clickedSignUp(){
            this.store.setReturnToUrl(this.routeToUrl)
            this.router.navigate(['/subscriptions/pricing'])
            this.dialogRef.close()
        }
        close() {
            this.dialogRef.close();
        }
    
  }
  