<form [formGroup]="changePasswordForm">

    @if(loading === true){
        <div class="data-loading-spinner" >
            <mat-spinner style="margin:auto" color="primary">
            </mat-spinner>
        </div>
    }

    <div style="flex:1; margin-right: 1em;">
        <div class="login-form-control" style="display: flex; flex-direction: column;">
            <mat-form-field class="full-width"  [style.width.%]="100">
                <div style="display:flex">           
                    <div>
                        <input matInput [type]="passwordType$ | async" formControlName="currentPassword" #currentPassword id="currentPassword" placeholder="Password">
                    </div>
                    <div style="display: flex;">
                        <lib-svg-icon style="margin:auto" [color]="'darkGrey'" [iconName]="password_icon$ | async" [size]="'small'" (click)="showPasswordClicked()" ></lib-svg-icon>
                     </div>
                    
                </div>
                <mat-error >     
                    <!--*ngIf="changePasswordForm.get('currentPassword').hasError('errors')">  -->
                    <lib-mat-form-error-component 
                        [inputControl]="changePasswordForm.get('currentPassword').errors">
                    </lib-mat-form-error-component>
                </mat-error> 
    
            </mat-form-field>
        </div>
        
    </div>

    <div style="flex:1; margin-right: 1em;">

        <div class="login-form-control" style="display: flex; flex-direction: column;">
            <mat-form-field class="full-width"  [style.width.%]="100">
                <div style="display:flex">           
                    <div>
                        <input matInput [type]="passwordType$ | async" formControlName="password" #password id="password" placeholder="New Password">
                    </div>
                    <div style="display: flex;">
                        <lib-svg-icon style="margin:auto" [color]="'darkGrey'" [iconName]="password_icon$ | async" [size]="'small'" (click)="showConfirmPasswordClicked()" ></lib-svg-icon>
                     </div>
                    
                </div>
                <mat-error >     <!--*ngIf="changePasswordForm.get('currentPassword').hasError('errors')">  -->
                    <lib-mat-form-error-component 
                        [inputControl]="changePasswordForm.get('password').errors">
                    </lib-mat-form-error-component>
                </mat-error> 
    
            </mat-form-field>
        </div>
        
    </div>

    <!--
    <div style="flex:1; margin-right: 1em;">
        <mat-form-field style="width:100%">
             <input matInput formControlName="confirmPass" type="password" placeholder="Confirm Password">
            <mat-error align="start" *ngIf="formErrors.password" class="">{{ formErrors.confirmPass }}</mat-error> 
            <input matInput type="password" 
            placeholder="Confirm Password" 
            [errorStateMatcher]="matcher"
            formControlName="confirmPass">
            
            <mat-error *ngIf="changePasswordForm.get('confirmPass').hasError('errors')">            
                <lib-mat-form-error-component 
                [inputControl]="changePasswordForm.get('confirmPass')">
            </lib-mat-form-error-component>
        </mat-error>
    </mat-form-field>
</div>
-->

<div class="onboarding-next-button-holder">
    <button mat-button class="onboarding-action-button primary-font"  [disabled]="!isValid$.value" [disabled]="inTimeOut" (click)="clickedSubmit(changePasswordForm.value)">Submit</button>
    </div>
</form>
