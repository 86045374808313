import {
  Component,
  inject,
  Output,
  EventEmitter,
  OnChanges,
  Input,
  Inject,
  OnInit,
  OnDestroy,
  SimpleChanges,
  PLATFORM_ID,
} from '@angular/core';

//SignUp needs to import AuthService
import { CommonModule, NgClass } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';

import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  MatFormErrorComponent,
  MatFormErrorStateMatcher,
} from '@sybl/common-ui';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

//import { PasswordValidators } from '../../shared/services/passwordFormValidators.service'

//import { ObservableMediaService } from '@angular/flex-layout';
import { AuthStatusStore } from '@sybl/feature-auth-state';

import {
  PasswordValidators,
  UsernameValidators,
} from '@sybl/feature-auth-data-access';
import { ScrollService } from '@sybl/common-services';

import { IUserSignUp } from '@sybl/feature-auth-models';
import { AuthBackendService } from '@sybl/feature-auth-data-access';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SvgIconComponent } from '@sybl/feature-custom-elements-ui';

const DIRECTIONS = ['', '-reverse'];

@Component({
  // tslint:disable-next-line: component-selector
  standalone: true,
  providers: [AuthBackendService, PasswordValidators, UsernameValidators],
  imports: [
    CommonModule,
    MatButtonModule,
    SvgIconComponent,
    NgClass,
    MatCardModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatFormErrorComponent,
  ],
  selector: 'lib-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit, OnDestroy, OnChanges {
  readonly store = inject(AuthStatusStore);
  @Input() siteName!: string;
  @Input() loading!: boolean;

  @Output() signUpOut = new EventEmitter<IUserSignUp>();
  get firstName() {
    return this.signUpForm.get('firstName');
  }
  get lastName() {
    return this.signUpForm.get('lastName');
  }
  get username() {
    return this.signUpForm.get('username');
  }
  get email() {
    return this.signUpForm.get('email');
  }
  get password() {
    return this.signUpForm.get('password');
  }
  //get confirmPass() { return this.signUpForm.get('confirmPass'); }
  get getSiteName() {
    return this._siteName;
  }
  showForm = false; // Dont send or show form on server view

  _siteName = '';
  password$!: Observable<any>;
  confirmPass$!: Observable<any>;
  username$!: Observable<any>;
  matcher = new MatFormErrorStateMatcher();
  signUpFormValue$!: Observable<any>;
  showPassword$ = new BehaviorSubject<boolean>(false);
  password_icon$ = new BehaviorSubject<string>('password_hide.svg');
  passwordType$ = new BehaviorSubject<string>('password');
  password_show: string;
  password_hide: string;
  validFormObservable$ = new Subscription();
  isValid$ = new BehaviorSubject<boolean>(false);
  get isValid(){return this.isValid$.value}

  signUpForm: FormGroup;
  isDisabled$: any;
  direction = '';
  invalidForm!: boolean;
  googleIcon!: string;
  facebookIcon!: string;

  public activeMediaQuery = '';
  private _onDestroy = new Subject();
  platform!: boolean;

  toggleDirection() {
    const next = (DIRECTIONS.indexOf(this.direction) + 1) % DIRECTIONS.length;
    this.direction = DIRECTIONS[next];
  }

  constructor(
    @Inject('appUrls') private appUrls: any, // EnvironmentAppUrls,
    @Inject(PLATFORM_ID) private platformId: any,

    private validatePassword: PasswordValidators,
    private validateUsername: UsernameValidators,
    private scrollService: ScrollService
  ) {
    //this.loading$ = this.store.select(fromRoot.getLoading);
    this.googleIcon = this.appUrls.IMAGES_URL + 'google_30.png';
    this.facebookIcon = this.appUrls.IMAGES_URL + 'facebook_30.png';

    this.signUpForm = new FormGroup({
      siteName: new FormControl(this.siteName),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.maxLength(20)]),
      username: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: this.validateUsername.usernameValidator(),
      }),
      email: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: this.validateUsername.emailValidator({
          minLength: 7,
          maxLength: 53,
        }),
      }),
      password: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: this.validateUsername.passwordValidator({
          minLength: 7,
          maxLength: 23,
        }),
      }),

      /*{ validators: [Validators.required, Validators.pattern(/^[a-zA-Z0-9!@#$%^&*()]+$/), Validators.maxLength(20), Validators.minLength(6)],
        asyncValidators:this.validatePassword.passwordValidator(), //Not really using this at the moment
        //updateOn: 'blur',
      },*/
      /*
      confirmPass: new FormControl(
        null,
        { validators: [this.validatePassword.confirmPasswordValidator()],
          //updateOn: 'blur',

        }
        ),
        */
    });

    this.signUpForm.statusChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((status) => {
        if (status === 'INVALID') {
          this.isValid$.next(false);
        } else if (status === 'VALID') {
          this.isValid$.next(true);
        }
      });
    this.password_hide = appUrls['IMAGES_URL'] + 'password_hide.svg';
    this.password_show = appUrls['IMAGES_URL'] + 'password_show.svg';
  }

  ngOnInit() {
    if (typeof window !== 'undefined') {
      //this.scrollService.scrollToTop();
      this.showForm = true;
    }
  }
  // this._watcher = this.watchMQChanges();

  ngOnDestroy() {
    this._onDestroy.next(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['siteName']) {
      this._siteName = changes['siteName'].currentValue;
    }
  }

  //Todo need to enable uploading of Avatar Photo, and add GraphicMagic to ensure safety.
  signUp(): void {
    //May not have to use preventDefault with the latest version of reactive forms, look at Kara's video.
    //Pass data to the authService signUp method
    const signUpDetails: IUserSignUp = {
      wasSignUp: true,
      firstName: this.firstName!.value,
      lastName: this.lastName!.value,
      username: this.username!.value,
      email: this.email!.value,
      password: this.password!.value,
      avatar: this.appUrls.REGISTERED_AVATAR,
      //confirmPass: this.confirmPass!.value,
      returnToUrl: undefined,
      date_time: new Date(),
      siteName: this.getSiteName, //Change to use inject token site name.
    };

    this.signUpOut.emit(signUpDetails);
  }

  showPasswordClicked() {
    this.showPassword$.next(!this.showPassword$.value);
    if (this.showPassword$.value === true) {
      this.password_icon$.next('password_show.svg');
      this.passwordType$.next('text');
    } else {
      this.password_icon$.next('password_hide.svg');
      this.passwordType$.next('password');
    }
  }

  logoutUser(event: any) {
    event.preventDefault();
    return this.store.logoutUser();
  }

  errorChanged(event: any) {
    console.warn('Error Changed', event);
  }
}
