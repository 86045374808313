
    <div  class="login-dialog-container" ><!--/div>fxFlex="100" fxLayoutAlign="center center">-->
 
            <div class="login-dialog-close-holder" >
                <div class="login-close-dialog">
                   <!--

                       <lib-svg-non-state-button  
                       (click)="close()"
                       [nameIn]="'close'"
                       [iconType]="'close'"
                       [color]="'black'"
                       ></lib-svg-non-state-button>
                    -->
                </div>
            </div>

            @if(showAction!==''){
                <div class="login-action-text">
                    {{showAction}}
                </div>
            }
            

            <div class="login-avatar-holder">
                <img class="login-avatar" src="{{avatar}}">
                    @if(loading===true){
                    <div class="data-loading-spinner">
                        <mat-spinner style="margin:auto" color="primary">
                        </mat-spinner>
                    </div>
                }
            </div>

            @if(wrongPassword){

                <div  class="wrong-password">
                    Username or Password is incorrect
                    <br/>
                    <div *ngIf="timeOutSeconds! > 0"> Exceeded max number of login attempts, wait {{ timeOutSeconds }} seconds</div>
                </div>
            }
        
        <div class="login-form-container">
            <form class="login-form" [formGroup]="loginForm" (ngSubmit)="submitLogin()">
                <div class="login-form-control">
                    <mat-form-field class="full-width">
                        <input matInput type="text" formControlName="email" #email id="email" placeholder="Email or Username">
                    </mat-form-field>
                </div>
                <div class="login-form-control" style="display: flex; flex-direction: column;">
                    <mat-form-field class="full-width"  [style.width.%]="100">
                        <div style="display:flex">           
                            <div>
                                <input matInput [type]="passwordType$ | async" formControlName="password" #password id="password" placeholder="Password">
                            </div>
                            <div style="display: flex;">
                                <lib-svg-icon style="margin:auto" [color]="'darkGrey'" [iconName]="password_icon$ | async" [size]="'small'" (click)="showPasswordClicked()" ></lib-svg-icon>
                             </div>
                            
                        </div>
                    </mat-form-field>
                </div>
                <div class="login-button-container">
                    <!-- [disabled]="inTimeOut$ | async" -->
                    <button style="margin-bottom:1em; width:100%; background-color: #ce6925; color:white" mat-raised-button type="submit"
                    class="btn btn-default">Log In</button>
                    <div class="login-sign-up" (click)="clickedSignUp()">
                        Create Account
                    </div>
                    <div class="login-forgot-password"
                        (click)="clickedForgotPassword()"
                       > Forgot Password
                    </div>
                </div>
            </form>
        </div>
    </div>
