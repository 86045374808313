import { Injectable } from '@angular/core';
import { Observable, timer, of } from 'rxjs'

import { switchMap, map } from 'rxjs/operators'

import { AsyncValidatorFn, ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { AuthBackendService } from '../services';

//const validCharacters = new RegExp('^[^!<>?=+@{}_$%]*[0-9]+[^!<>?=+@{}_$%]*$');

@Injectable()
export class PasswordValidators {
    constructor(
        private authService: AuthBackendService,
        ) {

        }
    
    checkPassword(password:any) {
        // Could use
        return timer(200)
          .pipe(
            switchMap(() => {
              // Check if username is available
              return this.authService.checkPassword(password);
            })
          );
      }



      checkUsername(username:string) {
        // Could use
        return timer(750)
          .pipe(
            switchMap(() => {
              // Check if username is available
              return this.authService.checkUsername(username);
            })
          );
      }
    
      passwordValidator(): AsyncValidatorFn {
        // Could use
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
          return this.checkUsername(control.value)
            // Check if username is available
            
            .pipe(
              map(res=>{

                return {passwordIncorrect:false}
              })
            )
        }
      }

      


    currentPasswordValidator(): AsyncValidatorFn{
        return (control:AbstractControl):Observable<{ [key: string]: any} | null> =>{
            return this.checkPassword(control.value)
            .pipe(
                map(res=>{
     
                    if(res){
                        if(res.status==='bad'){
                            return { 'passwordIncorrect':true }
                        }
                        /*}else{
                            return { 'passwordIncorrect':false }
                        */
                       return null
                    }
                    return null
                })
            )
        }  
    }

    confirmPasswordValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const verifyPassword = control.value;

            if (verifyPassword) {
                const password = control.root.get('password').value;
                if (verifyPassword !== password) {
                    //store.dispatch(authActions.ConfirmPasswordErrorsUpdate({errors}))
                    return {'passwordIncorrect':true }
                }
                else {
                    
                    //store.dispatch(authActions.ConfirmPasswordErrorsUpdate({errors}))
                    return of(null);    
                }
            } else {

                return of(null);
            }

        }
    }
}

