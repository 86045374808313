
<div fxLayout='column' class="logout-dialog" ><!--/div>fxFlex="100" fxLayoutAlign="center center">-->
    <div class="logout-dialog-container" style="background-color: whitesmoke;">


        @if(avatar===undefined && permissions >= 3){
            <div class="logout-avatar-holder ">
              <div
                style="
                background-color: whitesmoke;
                border-radius: 63px;
                height: 116px;
                width: 123px;
                display: flex;
                margin-bottom: 1em;
                border: 6px solid #ce6925;
                "
              >
                <div style="color: #ce6925; 
                    margin: auto;
                    font-size: 6em;
                    font-weight: 600;
                ">
                  {{ usernameInitial }}
                </div>
              </div>
            </div>
            } @else if(avatar !== undefined && permissions >= 3){
                <div class="logout-avatar-holder">
                    <img class="logout-avatar" [src]="avatar">
                </div>
            } 
            <div class="onboarding-next-button-holder">
              <!-- [disabled]="inTimeOut$ | async" -->
            <button 
                mat-button 
                class="onboarding-action-button primary-font"
                (click)="logout()">
                Logout
            </button>
        </div>
        <div class="logout-cancel"
            (click)="cancel()"
        > Cancel
        </div>
    </div>
</div>
