<div class="smart-page-holder circle-background-images"> 
    <mat-card class="password-reset-mat">
        @if(forgotPasswordErrors===null){
        <div>
            @if(loading){
                <div class="data-loading-spinner" >
                    <mat-spinner style="margin:auto" color="primary">
                    </mat-spinner>
                </div>
            }
            
            <div style="text-align:center; margin-bottom: .5em;">
                <div class="password-reset-title"> 
                    Enter a new password for
                </div>
                
                <div style="padding-bottom:2em">
                    <span style="font-weight:500">{{  forgotPasswordUsername }} <br /></span>
                    email: <span style="font-weight:500">{{forgotPasswordEmail}}</span>
                </div>
                
                <lib-password-reset-form [passwordResetUsername]="forgotPasswordUsername"
                    [passwordResetEmail]="forgotPasswordEmail" [passwordResetToken]="passwordResetToken"
                    [token]="passwordResetToken">
                </lib-password-reset-form>
            </div>
        </div>
    }
    @if(forgotPasswordErrors!==null){
        <div>       
            <div>
                <!-- Forgot Password Icon -->
            <div class="password-icon-holder">
                <img class="email-icon" src="{{ forgotPasswordExpiredUrl }}">
            </div>
            <br/>
  
                <br/>
            
                <div style="text-align: center; margin-right: auto; margin-left: auto; font-size: 1.1em; font-weight: 500;">
                    Invalid or expired Reset Token<br>
                    <br/>
                    <span style="font-weight: bold;color:maroon">
                        {{ passwordResetToken }} <br />
                    </span>

                </div>
            </div>
            
            <br />
            <div style="width: 100%; height: 2.5em; margin-bottom: 16px; display: flex; justify-content: center;">

                <button style="flex:1; width:100%; background-color: #ce6925;; color:white;" mat-raised-button (click)="navigateTo()">
                Request Reset
            </button>
                </div>
            </div>
    }

    </mat-card>
</div>